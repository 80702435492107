import './styles.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark, faClose, faExternalLinkAlt, faTrash} from '@fortawesome/free-solid-svg-icons';
import {faGg} from "@fortawesome/free-brands-svg-icons";

import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import React, {useState} from 'react';
import {FileUploader} from "react-drag-drop-files";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from 'react-time-ago'
import LoadMoreFilesButton from "../LoadMoreFilesButton"
import PreviewFile from "../PreviewFile";
import NewDocumentButton from "../NewDocumentButton";
import {Prompt} from 'react-st-modal'


TimeAgo.addLocale(en)


function FileListing(props) {
    const [iframeClosed, setIframeClosed] = useState(true);
    const [dateRefresh, setDateRefresh] = useState(Date.now());
    const api = props.api;
    const [selectedFolder, setSelectedFolder] = useState(props.selectedFolder);
    const [drive, setDrive] = useState(props.drive);
    const [query, setQuery] = useState(props.query);
    const [expanded, setExpanded] = useState(false)


    React.useEffect(() => {

        setDrive(props.drive || {});
        setSelectedFolder(props.selectedFolder || {});
        console.log(props.selectedFolder.id, typeof (props.selectedFolder.id))
        setExpanded(typeof (props.selectedFolder.id) !== 'undefined' || props.query!=='');
        setQuery(props.query || '');

    }, [props.drive, props.selectedFolder, props.query])


    const showFile = (file) => {
        setIframeClosed(false);
        setDateRefresh(Date.now())
        api.selectFile(file)
    }


    let iframeUrl = props.selectedFile.webViewLink ?? '';
    iframeUrl = iframeUrl.replace('view?usp', 'preview?usp');

    function deleteFile(file) {
        let confirmOptions = {
            title: 'Confirm delete',
            message: `Are you sure you want to delete ${file.name}`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => api.deleteFile(file)
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        };
        // noinspection JSCheckFunctionSignatures
        confirmAlert(confirmOptions)
    }

    const newFile = async (parentFolder, mimeType) => {
        let options = {}
        if (mimeType === 'application/vnd.google-apps.folder') {
            //console.log("DRIVE IS",drive)
            const folderName = await Prompt("New Folder Name", {
                isRequired: true,
                defaultValue: ""
            })
            if (!folderName) {
                return;
            }
            options.name = folderName
        }
        api.createGoogleFile(parentFolder, mimeType, options)
            .then(async (res) => {
                let file = res.result;
                //console.log("Selecting...",file)
                if (mimeType === 'application/vnd.google-apps.folder') {
                    if (parentFolder.id === 'root') {
                        await api.refreshDrive(drive)
                    } else {
                        await api.refreshFolder(drive, parentFolder);

                    }

                } else {
                    api.showFiles(parentFolder).then((res) => {
                        showFile(file);
                    }).catch(console.error)
                }


            })
            .catch(console.error)
    }

    const checkForMoreLoading = async () => {
        // //return true; //@todo - change for later
        // const loadMoreButton = document.getElementById("btnLoadMore") ?? false
        // if (loadMoreButton && isVisible(loadMoreButton)) {
        //     //console.log("Load more files button is visible")
        //     loadMoreButton.innerHTML = "Loading More Files..."
        //     await showMore();
        // }
    }

    function showMore() {
        if (query !== '') {
            return api.queryGoogleDrive(query, props.nextFilesPageToken)
        } else {
            return api.showFiles(selectedFolder, props.nextFilesPageToken)

        }
    }

    const handleChange = (files) => {

        api.uploadFiles(files)

    };

    const files = props.files || [];
    let fileList = files.map((file) =>

        <div className="file__container" key={file.id} data-file-id={file.id}>

            <button data-file-id={file.id}
                    onClick={() => {
                        showFile(file)
                    }}>

                <img alt="file icon" src={file.iconLink}/>
                {file.name}

                <a className="new-window-link" target="_blank" rel="noreferrer" href={file.webViewLink}
                   onClick={(e) => {
                       e.stopPropagation();
                   }}>
                    <FontAwesomeIcon className="new-window-link" icon={faExternalLinkAlt}/>
                </a>

                <div className={"last-modified-container"}>
                    Last Modified <ReactTimeAgo date={new Date(file.modifiedTime)} locale="en-US"/>
                </div>

            </button>
            <button className="delete-file-link"
                    onClick={() => deleteFile(file)}
                    title={!file.capabilities.canDelete ? `Can't Delete - check sharing permissions` : 'Delete file'}
                    disabled={!file.capabilities.canDelete}>
                <FontAwesomeIcon className="" icon={faTrash}/>
            </button>
        </div>
    );

    if (files.length <= 0) {
        fileList = <>
            <div className="no-file__container">
                <div className="no-file">{props.api.isLoading === true ? 'Loading...' : 'No files'}</div>
            </div>
        </>;
    }


    let fileListingToolbar;
    let fileListingCSSClass = "file-listing__container--toolbar__container";
    let canAddNewFiles = props.drive.id === 'root';
    if (props.drive.id === 'shared' && props.selectedFolder.capabilities && props.selectedFolder.capabilities.canAddChildren === true) {
        canAddNewFiles = true;
    }
    if ((selectedFolder.name ?? '') !== '') {
        fileListingToolbar =
            <>


                <div
                    className={`file-listing__container--toolbar__container--new-files__container ${drive.id === 'shared' ? 'disabled' : ''}`}>

                    <div
                        className={"file-listing__container--toolbar__container--new-files__container--new-google-file-button__container"}>
                        {
                            api.newDocMimeTypes.map((type) => (
                                <NewDocumentButton
                                    key={type.mimeType}
                                    mimeType={type.mimeType}
                                    description={type.description}
                                    iconUrl={type.iconUrl}
                                    disabled={!canAddNewFiles}
                                    parentFolder={props.selectedFolder}
                                    onClick={(parentFolder, mimeType) => newFile(parentFolder, mimeType)}
                                />
                            ))}
                    </div>
                    <div className="file-upload-container" key={"new-file-upload"}
                         title={(canAddNewFiles) ? 'Upload Files' : 'New Files Not Allowed - Check sharing permissions'}
                    >
                        <FileUploader
                            handleChange={handleChange} name="file" multiple={true}
                            label={`Upload or drag and drop file(s) here!`}
                            classes={`drop-zone`}
                            disabled={!canAddNewFiles}
                        />
                    </div>

                </div>
                <div className={"file-listing__container--toolbar__container--folder-name__container"}>
                    <h2>{props.selectedFolder.name ?? ''}</h2>
                    <a target="_blank" rel="noreferrer" href={props.selectedFolder.webViewLink}>
                        <FontAwesomeIcon className="new-window-link" icon={faExternalLinkAlt}/>
                    </a>
                </div>

            </>;
    } else if (!api.isLoading) {
        //fileListingCSSClass += " is-hidden"
        fileListingToolbar =
            <>
                <div className={"no-drive-selected__container"}>
                    <h2>Select a Drive/Folder</h2>
                </div>

            </>;
    }


    return (
        <div className={`files__container ${expanded ? 'expanded' : ''}`}>

            <button className={`close-button`}
                    onClick={() => {
                        setExpanded(false)
                    }}>
                <FontAwesomeIcon icon={faClose}/>
            </button>

            <div className="file-listing__container">
                <div className={fileListingCSSClass}>

                    {fileListingToolbar}

                </div>


                <div
                    className={`file-listing__container--files__container ${drive.id === 'shared' ? 'shared-files' : ''}
                    ${props.files.length <=0 ? 'no-files' : ''}`}
                    onScroll={checkForMoreLoading}
                    onLoad={checkForMoreLoading}
                >


                    {fileList}
                    <LoadMoreFilesButton
                        api={props.api}
                        showMore={showMore}
                        visible={props.nextFilesPageToken ?? false}
                        selectedFolder={props.selectedFolder}
                        nextFilesPageToken={props.nextFilesPageToken}
                    />
                    <FontAwesomeIcon icon={faGg}/>


                </div>

                <div className={"file-listing__container--footer"}/>

            </div>


            <PreviewFile
                iframeUrl={iframeUrl}
                iframeClosed={iframeClosed}
                dateRefresh={dateRefresh}

            />

        </div>


    )
        ;
}

export default FileListing;