import React, {useState} from 'react';
import './styles.scss';
import Drive from '../Drive';



function DriveList(props) {

    const api = props.api;
    const [drives, setDrives] = useState(props.drives || []);


    React.useEffect(() => {

        setDrives(props.drives || []);
        //console.log("DRIVES ARE NOW",props.drives)

    }, [props.drives])

    return (


        <div className={props.className}>
            {
                drives.map((drive) => (
                    // <div>{drive.name}</div>
                    <Drive drive={drive}
                           key={drive.id}
                           api={api}

                    />
                ))
            }

        </div>

    );

}

export default DriveList;