export function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString});


}

export function isVisible(element) {
    if (element) {
        let position = element.getBoundingClientRect();
        return (position.top < window.innerHeight && position.bottom >= 0)

    }
    return false;

}

export function hasClass(ele, cls) {
    return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

export function addClass(ele, cls) {
    if (!hasClass(ele, cls)) ele.className += " " + cls;
}

export function removeClass(ele, cls) {
    if (hasClass(ele, cls)) {
        let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
        ele.className = ele.className.replace(reg, ' ');
    }
}

export function getDriveWebViewLink(drive){
    if (drive.id === 'shared') {
        return "https://drive.google.com/drive/shared-with-me"
    }
    let prefix = "https://drive.google.com/drive/u";
    return prefix + "/0/folders/" + drive.id;
}