import './styles.scss';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';


function Search(props) {

    const [query, setQuery] = useState(props.query ?? '');
    useEffect(()=>{
        setQuery(props.query)
    },[props.query])

    function doQuery(e) {
        if (e !== '') {
            setQuery(e.target.value);
            props.onChange(e);
        }
    }

    if(!props.visible){
        return null;
    }
    return (
        <div className="search__container">
            <label htmlFor="searchDriveInput">
                <FontAwesomeIcon icon={faSearch} className="icon-search"/>
            </label>
            <input
                id="searchDriveInput"
                type="text"
                placeholder={"Search"}
                value={query}
                onChange={event => doQuery(event)}>

            </input>
        </div>
    );

}

export default Search;