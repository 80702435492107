import './styles.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCirclePlus} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from "react";

function NewDocumentButton(props) {
    const [imgLoaded,setImgLoaded] = useState(false);

    return (

        <div className={`new-document-button__container  ${imgLoaded ? 'img-loaded' : ''}`}
        >

            <button
                disabled={props.disabled}
                title={!props.disabled ? `New ${props.description}` : 'New Files Not Allowed - Check sharing permissions'}
                onClick={() => {
                    props.onClick(props.parentFolder, props.mimeType)
                }}
            >
                <FontAwesomeIcon className="icon" icon={faCirclePlus}/>
                <img onLoad={()=>{setImgLoaded(true)}} src={props.iconUrl} alt={"icon"}/>
                {/*<div>New {props.description}</div>*/}


            </button>
        </div>
           
    )


}

export default NewDocumentButton