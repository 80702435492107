import './styles.scss';
import React, {useState} from 'react';
import Loading from 'react-loading-components';

function LoadingIndicator(props){
    if(props.visible) {
        return (
            <div className={props.className || 'loading-callout__container'}>
                <div className="loading-animation__container">
                    {/*<Loading type="rings" width={400} height={400} fill="#151b26"/>*/}
                    <Loading type={props.type || 'rings'}
                             width={props.width || '400'}
                             height={props.height || '100'}
                             fill={props.fill || 'silver'}/>
                </div>
            </div>
        )
    }
    return null;
}

export default LoadingIndicator;