import {gapi} from 'gapi-script';
import {dataURItoBlob} from "../libs/customLibrary";
import {GOOGLE_DRIVE_UPLOAD_ENDPOINT} from "./gapiConfig";

class myGDrive {
    constructor() {
        this.gapi = gapi;
        this.defaultFolderPageSize = null;
        this.defaultFilePageSize = null;
    }

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */

    /**
     *  listenCallback is Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */
    initClient = (options, listenCallback) => {
        return new Promise((resolve, reject) => {
            this.gapi.client
                .init(options)
                .then(function (res) {
                    // Listen for sign-in state changes.
                    // noinspection JSUnresolvedFunction,JSUnresolvedVariable
                    gapi.auth2.getAuthInstance().isSignedIn.listen(listenCallback);

                    // Handle the initial sign-in state.
                    // noinspection JSUnresolvedFunction,JSUnresolvedVariable
                    listenCallback(gapi.auth2.getAuthInstance().isSignedIn.get());
                    resolve(res);
                }, function (error) {
                    console.error("myGDrive error on initClient", error)
                    reject(error)
                });
        })
    }

    clientLoaded = (callback) => {
        this.gapi.load('client:auth2', callback);
    }

    signIn = () => {
        this.gapi.auth2.getAuthInstance().signIn();
    }

    signOut = () => {
        return this.gapi.auth2.getAuthInstance().signOut()
    }

    signedInUser = () => {
        return this.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
    }

    /** basic requests **/
    #getFileListRequest(query,nextPageToken) {
        let _r= {
            pageSize: this.defaultFilePageSize,
            includeItemsFromAllDrives: true,
            supportsAllDrives: true,
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, webContentLink, webViewLink,iconLink,parents,capabilities)',
            q: query
        };
        if (nextPageToken) {
            _r.pageToken = nextPageToken
        }
        return _r;
    }

    /**gapi client wrappers */
    #drivesListRequest = (req) => {
        return this.gapi.client.drive.drives
            .list(req)
    }

    #filesListRequest = (req) =>{
        return this.gapi.client.drive.files
            .list(req);
    }

    #filesCreateRequest = (req)=>{
        return this.gapi.client.drive.files
            .create(req);
    }
    #filesGetRequest = (req) =>{
        return this.gapi.client.drive.files
            .get(req);
    }
    #fileUploadRequest = (req) =>{
        // noinspection JSUnresolvedVariable
        return fetch(GOOGLE_DRIVE_UPLOAD_ENDPOINT, {
            method: 'POST', headers: {Authorization: 'Bearer ' + this.gapi.auth.getToken().access_token},//cli gapi.auth.getToken().access_token},
            body: req
        })
    }
    /** end gapi client wrappers*/


    deleteFile = (file) => {
        return new Promise((resolve, reject) => {
            this.gapi.client.drive.files.delete({
                fileId: file.id
            })
                .then(async (response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    getDrives = () => {
        let request = {
            fields: 'nextPageToken, drives(id, name,capabilities)',
            includeItemsFromAllDrives: true,
            supportsAllDrives: true, //q: "'root' in parents and mimeType = 'application/vnd.google-apps.folder'"
        }
        return this.#drivesListRequest(request);

    }

    getFolders = (parentDriveOrFolder,nextPageToken)=>{
        let _query = `'${parentDriveOrFolder.id}' in parents and mimeType = 'application/vnd.google-apps.folder' and trashed=false`;
        let request = {
            pageSize: this.defaultFolderPageSize,
            includeItemsFromAllDrives: true,
            supportsAllDrives: true,
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime,webViewLink,capabilities)',
            q: _query
        }
        if (nextPageToken) {
            request.pageToken = nextPageToken
        }
        return this.#filesListRequest(request)
    }

    getSharedFolders = (nextPageToken) =>{
        let _query = `sharedWithMe and mimeType = 'application/vnd.google-apps.folder' and trashed=false`;
        let request = {
            pageSize: this.defaultFolderPageSize,
            includeItemsFromAllDrives: true,
            supportsAllDrives: true,
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime,webViewLink,shared,capabilities)',
            q: _query
        }
        if (nextPageToken) {
            request.pageToken = nextPageToken
        }
        return this.#filesListRequest(request)
    }

    getSharedFiles = (nextPageToken)=>{
        let query  = `sharedWithMe and mimeType != 'application/vnd.google-apps.folder' and trashed=false`;
        let request = this.#getFileListRequest(query,nextPageToken)
        return this.#filesListRequest(request)
    }

    getFilesForFolder = (parentFolder,nextPageToken)=>{
        let query = `'${parentFolder.id}' in parents and mimeType != 'application/vnd.google-apps.folder' and trashed=false`;
        let request = this.#getFileListRequest(query,nextPageToken)
        return this.#filesListRequest(request)
    }

    queryGoogleDrive =(queryString,nextPageToken)=>{
        let query = `name contains '${queryString}' and mimeType != 'application/vnd.google-apps.folder'`;
        let request = this.#getFileListRequest(query,nextPageToken)
        return this.#filesListRequest(request)
    }

    createGoogleFile = (parent, mimeType, options = {})=>{
        let createReq = {
            mimeType: mimeType,
            parents: [parent.id],
        };
        createReq = {...createReq, ...options}
        return this.#filesCreateRequest(createReq)
    }

    getFileData = (file)=>{
        let request = {
            fileId: file.id,
            fields: 'id,webViewLink'
        }
        return this.#filesGetRequest(request)
    }

    uploadFile = (file,fileData,folderId)=>{
        let fileBlob = dataURItoBlob(file)
        let metadata = {
            'name': fileData.name, 'mimeType': fileData.type, 'parents': [folderId]
        };

        const fd = new FormData();
        fd.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
        fd.append('file', fileBlob);
        return this.#fileUploadRequest(fd)
    }
}


export {gapi};
let newMyGDrive = new myGDrive();
export {newMyGDrive}

