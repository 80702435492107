import React, {useState} from 'react';
import './styles.scss';
import Folder from '../Folder';

function FolderList(props) {

	const drive = props.drive;
	const isOpen = props.isOpen;
	const api = props.api;

	const [folders,setFolders] = useState(props.folders || []);

	React.useEffect(() => {

		setFolders(props.folders || []);

	}, [props.folders])

	return (
		<div className={`folder-list__container ${ !isOpen ? ' hide-up': ' show-down'}`}>
			{
				folders.map( (folder) => (
					<Folder name={folder.name}
							folder={folder}
							key={folder.id}
							active={false}
							drive={drive}
							api={api}
							nextPageToken={folder.nextPageToken}
							subfolders={folder.subfolders}

					/>))
			}
		</div>
	);

}

export default FolderList;