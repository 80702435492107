import './styles.scss';
import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight} from "@fortawesome/free-solid-svg-icons";

function ExpandCollapseButton(props) {
    const [isOpen, setIsOpen] = useState({...props.isOpen})
    const [folders,setFolders] = useState(props.folders || [])
    React.useEffect(() => {
        setIsOpen(props.isOpen);
        setFolders(props.folders);


    }, [props.isOpen,props.folders])


    return (
        <div className={"expand-collapse-icon__container"}
             title={"Toggle Folders"}
             onClick={props.onClick}
          >
            <FontAwesomeIcon
                icon={faCaretRight}
                className={`expand-collapse-icon${isOpen === true ? '--expanded' : '--closed'} ${folders.length <= 0 ? ' is-hidden' : ''}`}
            />
        </div>
    )
}

export default ExpandCollapseButton