import {newMyGDrive as myGDrive} from './sdk/myGDrive';
import React, {useState, useEffect, useCallback} from 'react';
import GoogleDriveImage from './assets/img/google-drive.png';
import ReactGA from 'react-ga';

import DriveList from './Components/DriveList';
import FileListing from './Components/FileListing';
import Search from './Components/Search';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import {getDriveWebViewLink} from "./libs/customLibrary";
import {useSetState} from "./libs/hooks";
import {
    CLIENT_ID,
    TRACKING_ID,
    DISCOVERY_DOCS,
    SCOPES,
    MIME_TYPES, getIconUrl
} from './sdk/gapiConfig'
import LoadingIndicator from "./Components/LoadingIndicator";
import LoginLogOutButton from "./Components/LoginLogoutButton";
import UserSummary from "./Components/UserSummary/index.";

ReactGA.initialize(TRACKING_ID);


function App() {

    const [signedInUser, setSignedInUser] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [drives, setDrives, getDrives] = useSetState([]);
    const [files, setFiles, getFiles] = useSetState([]);
    const [selectedFile, setSelectedFile] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState({});
    const [selectedDrive, setSelectedDrive] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [foldersVisible,setFoldersVisible] = useState(true);
    const [nextFilesPageToken, setNextFilesPageToken] = useState(false)
    const allowedTypes = ['Google Doc', 'Google Sheet', 'Google Drawing', 'Folder']
    const newDocMimeTypes =
        MIME_TYPES.filter((type) => {
            return allowedTypes.includes(type.description)
        })
            .map((t) => {
                t.iconUrl = getIconUrl(t.mimeType)
                return t
            });


    const createGoogleFile = (parent, mimeType, options = {}) => {

        return new Promise((resolve, reject) => {
            setIsLoading(true)
            myGDrive.createGoogleFile(parent, mimeType, options)
                .then((res) => {
                    let file = res.result
                    myGDrive.getFileData(file).then((res) => {
                        setIsLoading(false);
                        resolve(res);
                    })
                        .catch((error) => {
                            setIsLoading(false)
                            reject(error)

                        })

                })
                .catch((error) => {
                    setIsLoading(false)
                    reject(error)

                })
        })


    }

    const selectDrive = async (drive) => {
        setSelectedFolder(false);
        setSelectedDrive(drive);
        setQuery('')
        drive.folders = []; // clear out because user selected drive - full refresh
        await showFiles(drive);
        await getDriveFolders(drive, drive.nextPageToken ?? false);
        drive.webViewLink = getDriveWebViewLink(drive);
        setSelectedFolder(drive); // to show heading on file listing
    };

    const selectFolder = async (drive, folder) => {
        setQuery('')
        setSelectedFile(false);
        setSelectedFolder(folder);
        setSelectedDrive(drive);
        //console.log(folder)
        folder.subfolders = [];
        if (!folder.nextPageToken) {
            await getSubFolders(drive, folder, folder.nextPageToken ?? false);
        }
        await showFiles(folder);
    };

    const refreshDrive = async (drive) => {
        drive.nextPageToken = false; // so we start all over with folders
        drive.folders = [];

        if (drive.id === 'shared') {
            await getSharedFolders(drive)
        } else {
            await getDriveFolders(drive);
        }


    }

    const refreshFolder = async (drive, folder) => {
        folder.subfolders = [];
        folder.nextPageToken = false;
        await getSubFolders(drive, folder)


    }

    const selectFile = (file) => {
        setSelectedFile(file);
    };

    const sendToGoogleDrive = (file, fileData) => {
        setIsLoading(true);
        myGDrive.uploadFile(file, fileData, selectedFolder.id)
            .then(response => response.json())
            .then(async jsonResp => {
                setIsLoading(false);
                await selectFolder(selectedDrive, selectedFolder)
            })

    }

    const uploadFiles = (files) => {
        let warningShown = false;

        Object.values(files).forEach((file) => {
            setIsLoading(true);
            if (file.type === '' && !warningShown) {
                alert('Only files Can be uploaded.  Folders will be ignored');
                warningShown = true;
                setIsLoading(false);
                return;
            }

            let reader = new FileReader();
            reader.onloadend = (f) => {
                sendToGoogleDrive(f.target.result, file)
            }
            // noinspection JSCheckFunctionSignatures
            reader.readAsDataURL(file)
        })
    }

    const deleteFile = (file) => {
        myGDrive.deleteFile(file)
            .then(async (response) => {
                await selectFolder(selectedDrive, selectedFolder)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleClientLoad = () => {
        myGDrive.clientLoaded(initClient)
    };

    const updateSigninStatus = (isSignedIn) => {
        setIsLoading(true);
        // prompt user to sign in
        if (isSignedIn) {
            // Set the signed in user
            // noinspection JSUnresolvedFunction
            let signedInUser = myGDrive.signedInUser();
            setSignedInUser(signedInUser);
            setIsSignedIn(true);
            showDrives().then((drives) => {
                setDrives(drives);
                setIsLoading(false);
            });

        } else {
            // prompt user to sign in
            setIsSignedIn(false);
            myGDrive.signIn();
        }
    };

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    const initClient = () => {
        setIsLoading(true);
        myGDrive.initClient({
                clientId: CLIENT_ID, discoveryDocs: DISCOVERY_DOCS, scope: SCOPES,
            },
            updateSigninStatus).then((res) => {
            //setIsLoading(false);
        })
    };

    /**
     *  Sign out the user upon button click.
     */
    const handleSignOutClick = (event) => {
        setIsLoading(true);
        setSignedInUser(false);
        setFiles([]);
        setSelectedFile(false);
        setSelectedFolder(false);
        setDrives([]);
        // noinspection JSUnresolvedFunction,JSUnresolvedVariable
        myGDrive.signOut().then(() => {

            setIsLoading(false);
        });
    };

    const showDrives = () => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            myGDrive.getDrives()
                .then((response) => {

                    setDrives([]);
                    const res = JSON.parse(response.body);
                    let _drives = res.drives;
                    _drives.unshift({'name': 'My Drive', 'id': 'root'});

                    let _sharedDrive = {};
                    _sharedDrive.id = 'shared'
                    _sharedDrive.name = 'Shared With Me'
                    _sharedDrive.folders = res.files;
                    getSharedFolders(_sharedDrive).then((sharedDrives) => {
                        _drives.push(...sharedDrives)
                        resolve(_drives)
                        setIsLoading(false);
                    })
                })
                .catch((error) => {
                    reject(error);
                    setIsLoading(false);
                    console.error(error)
                })
        });


    };

    const getSharedFolders = (parentDrive, nextPageToken) => {

        return new Promise((resolve, reject) => {
            myGDrive.getSharedFolders(nextPageToken)
                .then(async function (response) {
                    const res = JSON.parse(response.body);
                    parentDrive.nextPageToken = res.nextPageToken ?? false
                    let _drives = await getDrives();
                    if (!_drives.find((drive) => {
                        return drive.id === 'shared'
                    })) {
                        parentDrive.folders = res.files
                        _drives.push(parentDrive)
                    } else {
                        _drives = fixDrivefolders(parentDrive.id, _drives, res.files, res.nextPageToken ?? false);
                    }
                    resolve([..._drives]);
                }, function (error) {
                    // setIsLoading(false);
                    reject(error)
                });
        });

    }

    const getDriveFolders = (parentDrive, nextPageToken = false) => {
        if (parentDrive.id === 'shared') {
            return getSharedFolders(parentDrive, nextPageToken)
        }

        return new Promise((resolve, reject) => {
            myGDrive.getFolders(parentDrive, nextPageToken)
                .then(async function (response) {
                    const res = JSON.parse(response.body);
                    let _drives = await getDrives();
                    _drives = fixDrivefolders(parentDrive.id, _drives, res.files, res.nextPageToken ?? false);
                    setDrives([..._drives]);
                    resolve(_drives);

                }, function (error) {
                    reject(error)
                });
        })


    };

    const fixDrivefolders = (id, _drives, folders, nextPageToken) => {
        _drives.forEach(element => {
            if (element.id === id) {
                folders.DRIVE_ID = id;
                element.folders = element.folders ? element.folders.concat(folders) : folders;
                element.nextPageToken = nextPageToken;
            }
            if ((element.folders ?? []).length > 0) {
                element.folders = fixDrivefolders(id, element.folders, folders ?? []);
            }

        });
        return _drives;
    };

    const fixSubfolders = (id, _folders, files, nextPageToken) => {
        _folders.forEach(element => {
            if (element.id === id) {
                element.subfolders = element.subfolders ? element.subfolders.concat(files) : files;
                element.nextPageToken = nextPageToken;
            }
            if ((element.subfolders ?? []).length > 0) {
                element.subfolders = fixSubfolders(id, element.subfolders, files);
            }

        });
        return _folders;
    };

    const getSubFolders = (drive, parentFolder, nextPageToken = false) => {
        return new Promise((resolve, reject) => {
            myGDrive.getFolders(parentFolder, nextPageToken)
                .then(function (response) {
                    const res = JSON.parse(response.body);
                    let _folders = drive.folders;
                    _folders = fixSubfolders(parentFolder.id, _folders, res.files, res.nextPageToken ?? false);
                    drive.folders = _folders;
                    setDrives([...drives]);
                    resolve(res);
                }, function (error) {
                    reject(error)
                });
        })

    };

    const showFiles = (parentFolder, nextPageToken) => {
        return new Promise((resolve, reject) => {
            if (!nextPageToken) {
                setIsLoading(true);
                setFiles([]);
            }

            let myGDriveCall = (parentFolder.id === 'shared') ? myGDrive.getSharedFiles(nextPageToken) : myGDrive.getFilesForFolder(parentFolder, nextPageToken);
            myGDriveCall
                .then(function (response) {
                    const res = JSON.parse(response.body);
                    if (nextPageToken) {
                        setFiles(files.concat(res.files))
                    } else {
                        setFiles(res.files);
                    }

                    if (!nextPageToken) {
                        setIsLoading(false);
                    }

                    if (res.nextPageToken) {
                        setNextFilesPageToken(res.nextPageToken);
                    } else {
                        //setHasMoreFiles(false);
                        setNextFilesPageToken(false);
                    }
                    resolve(res)

                }, function (error) {
                    setIsLoading(false);
                    reject(error)
                });
        })
    };

    const queryGoogleDrive = useCallback((queryString, nextPageToken) => {

        if (!nextPageToken) {
            setIsLoading(true);
            setFiles([]);
        }
        return myGDrive.queryGoogleDrive(queryString, nextPageToken)
            .then(async function (response) {
                const res = JSON.parse(response.body);
                let searchFolder = {}; //mimick structure
                searchFolder.name = "Results for " + queryString
                searchFolder.files = res.files;
                setSelectedFolder(searchFolder);

                if (nextPageToken) {
                    let _files = await getFiles();
                    setFiles(_files.concat(res.files))
                } else {
                    setFiles(res.files);
                }

                if (!nextPageToken) {
                    setIsLoading(false);
                }

                if (res.nextPageToken) {
                    setNextFilesPageToken(res.nextPageToken);
                } else {
                    setNextFilesPageToken(false);
                }

                setIsLoading(false);

            }, function (error) {
                console.error(error)
                setIsLoading(false);
            });

    }, [])

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            if (query !== '') {
                await queryGoogleDrive(query)
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [query, queryGoogleDrive]);

    //'global' functions to pass to child components

    const api = {
        getDriveFolders,
        getSubFolders,
        refreshDrive,
        refreshFolder,
        selectDrive,
        selectFolder,
        selectFile,
        uploadFiles,
        deleteFile,
        showFiles,
        queryGoogleDrive,
        createGoogleFile,
        selectedFolder,
        newDocMimeTypes,
        isLoading,
    }

    return (
        <div className="App">

            <LoadingIndicator visible={isLoading}/>

            <div className={`login__container ${isSignedIn ? '' : 'not-logged-in'}`}>
                <UserSummary className={"signed-in-message"}
                             signedIn={isSignedIn}
                             userAvatar={signedInUser ? signedInUser?.getImageUrl() ?? '' : ''}
                             userName = {signedInUser ? signedInUser?.getName() ?? '': ''}
                             userEmail = {signedInUser ? signedInUser?.getEmail() ?? '': ''}
                />
                <Search
                    query={query}
                    visible={isSignedIn}
                    onChange={(event) => setQuery(event.target.value)}
                />
                <LoginLogOutButton onClick={signedInUser ? handleSignOutClick : handleClientLoad}
                                   src={GoogleDriveImage}
                                   className={`login-button`}
                                   message={signedInUser ? "Logout / Change Google Account" : "Log into Google Drive"}
                />
            </div>

            <div className={`viewer__container ${isLoading ? 'is-loading' : ''}`}>




                <div className={`main-folder-list__container ${!foldersVisible ? 'hidden' : ''}`}>




                    <DriveList drives={drives}
                               api={api}
                               className={"drive-list__container"}

                    />

                </div>


                <FileListing
                    api={api}
                    drive={selectedDrive}
                    selectedFolder={selectedFolder || ''}
                    selectedFile={selectedFile}
                    files={files}
                    nextFilesPageToken={nextFilesPageToken}
                    query={query}
                />


            </div>


        </div>);
}


export default App;