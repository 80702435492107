//Google drive api settings
// Client ID and API key from the Developer Console
export const CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID; //const API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
export const TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID; // YOUR_OWN_TRACKING_ID


// Array of API discovery doc URLs for APIs
export const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
//  const SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly';
export const SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive';
export const GOOGLE_DRIVE_UPLOAD_ENDPOINT = 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart'
//https://www.googleapis.com/auth/drive.file

export const ICON_URL_ENDPOINT = "https://drive-thirdparty.googleusercontent.com/16/type/application"
export const MIME_TYPES = [
    {"description": "Google Audio", "mimeType": "application/vnd.google-apps.audio"},
    {"description": 'Google Doc', "mimeType": "application/vnd.google-apps.document"},
    {"description": "Google Sheet", "mimeType": "application/vnd.google-apps.spreadsheet"},
    {"description": "Google Third Party Shortcut", "mimeType": "application/vnd.google-apps.drive-sdk"},
    {"description": "Google Drawing", "mimeType": "application/vnd.google-apps.drawing"},
    {"description": "Google DriveFile", "mimeType": "application/vnd.google-apps.file"},
    {"description": "Folder", "mimeType": "application/vnd.google-apps.folder"},
    {"description": "Google Form", "mimeType": "application/vnd.google-apps.form"},
    {"description": "Google Fusion Table", "mimeType": "application/vnd.google-apps.fusiontable"},
    {"description": "Google Jamboard", "mimeType": "application/vnd.google-apps.jam"},
    {"description": "Google Map", "mimeType": "application/vnd.google-apps.map"},
    {"description": "Google Photo", "mimeType": "application/vnd.google-apps.photo"},
    {"description": "Google Slide", "mimeType": "application/vnd.google-apps.presentation"},
    {"description": "Google App Script", "mimeType": "application/vnd.google-apps.script"},
    {"description": "Google Shortcut", "mimeType": "application/vnd.google-apps.shortcut"},
    {"description": "Google Site", "mimeType": "application/vnd.google-apps.site"},
    {"description": "app", "mimeType": "application/vnd.google-apps.unknown"},
    {"description": "video", "mimeType": "application/vnd.google-apps.video"}
]

export function getIconUrl(mimeType){
    return mimeType.replace('application',ICON_URL_ENDPOINT);
}