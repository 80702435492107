import './styles.scss';
import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRotate} from "@fortawesome/free-solid-svg-icons";



function RefreshIcon(props) {
    const [isRefreshing,setIsRefreshing] = useState(false)


    const iconCSSClassName = props.iconCSSClassName ?? "refresh-folder-icon"

    async function onClick (){
        setIsRefreshing(true)
        await props.onClick();
        setTimeout(async ()=>{
            setIsRefreshing(false)
        },500)


    }

    return (
        <div className={props.className ?? "refresh-folder-icon__container"}
             title={"Reload folders"}>
            <FontAwesomeIcon icon={faRotate}
                             onClick={onClick}
                             className={`refresh-icon ${iconCSSClassName} ${isRefreshing===true ? 'is-refreshing' : ''}`}/>
        </div>
    )

}

export default RefreshIcon;