import './styles.scss';
import React, {useState} from 'react';

function UserSummary(props) {

    if (props.signedIn) {
        return (
            <div className={props.className || "signed-in-message"}>
                <img alt="profile avatar" className="profile-avatar"
                     src={props.userAvatar || ''}/>
                <span>
					 {`
							${props.userName || "UserName"}  | ${props.userEmail || ''}
							
						`}
					</span>


            </div>
        )
    }
    return null

}

export default UserSummary