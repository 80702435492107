import './styles.scss';
import React, {useState} from 'react';


function LoadMoreFilesButton(props) {
    const [isLoading,setIsLoading] = useState(false)
    const showMore = () =>{
        setIsLoading(true);
        props.showMore().then(()=>{
            setIsLoading(false)
        });
    }
    if(props.visible)
    return (
        <div className="file__container load-more-button ">

            <button id={"btnLoadMore"}
                    className={isLoading===true ? 'is-loading' : 'not-loading'}
                    onClick={ ()=>showMore() }>{isLoading===true ? 'Loading files...' : 'Click to load more files'}</button>
        </div>
    )
    return (<></>)
}

export default LoadMoreFilesButton