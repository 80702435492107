import './styles.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose, faExpandAlt, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import React,{useState} from 'react';


function PreviewFile(props) {

    const [iframeMaximized, setIframeMaximized] = useState(false);
    const [iframeClosed, setIframeClosed] = useState(props.iframeClosed);
    const [iframeUrl,setIframeUrl] = useState(props.iframeUrl)

    //using props.dateRefresh to trigger re open of preview if same file was closed and clicked again
    React.useEffect(() => {
        setIframeUrl(props.iframeUrl)
        setIframeClosed(props.iframeClosed)

    }, [props.iframeUrl,props.iframeClosed,props.dateRefresh])

    let previewContents;
    if (iframeUrl) {
         previewContents = <>
            <div className="iframe-toolbar__container">

                <FontAwesomeIcon className="maximize-window-link" icon={faExpandAlt}

                                 onClick={() => {
                                     setIframeMaximized(!iframeMaximized);
                                 }}
                />
                <FontAwesomeIcon className="close-window-link" icon={faWindowClose}

                                 onClick={() => {
                                     setIframeClosed(true);
                                     setIframeMaximized(false);
                                     setIframeUrl('')
                                 }}
                />
            </div>
            <iframe sandbox="allow-scripts allow-same-origin"
                    title="File Preview" width="100%" height="100%"
                    src={iframeUrl}/>
        </>;
    } else {
        previewContents = <div className="no-file__container">
            <div className="no-file"/>
        </div>;
    }


    let iframeContainerCSSClass = "file-iframe__container";
       if (!iframeClosed && iframeUrl!=='') {
        iframeContainerCSSClass += " is-activated";
    }
    if (iframeMaximized && !iframeClosed) {
        iframeContainerCSSClass += " is-maximized";
    }

    return (
        <div className={iframeContainerCSSClass}>
            <button className={`close-button`}
                    onClick={() => {
                        setIframeClosed(true)
                    }}>
                <FontAwesomeIcon icon={faClose}/>
            </button>
            {previewContents}
        </div>
    )

}

export default PreviewFile