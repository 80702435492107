import './styles.scss';
import React, {useEffect, useState,useCallback} from 'react';

import {faFolderPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {addClass, removeClass} from "../../libs/customLibrary";
import useIntersect from "../../libs/useIntersect"

const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100);

const LoadMoreFoldersButton = (props) => {

    const api = props.api;
    const parentId = props.folder ? props.folder.id : props.drive.id
    const className = props.className ?? '';
    const loadMoreSpan = document.getElementById(`loadMoreSpan_${parentId}`)
    const loadMoreButton = document.getElementById(`loadMoreButton_${parentId}`)
    const [isLoading, setIsLoading] = useState(false)
    const [ref, entry] = useIntersect({
        threshold: buildThresholdArray()
    });


    const showMore = useCallback(()=>{
        setIsLoading(true);
        loadMoreSpan.innerHTML = "Loading more folders..."
        addClass(loadMoreButton, 'is-loading');
        if (props.folder) {
            api.getSubFolders(props.drive, props.folder, props.folder.nextPageToken)
                .then(r => {
                    loadMoreSpan.innerHTML = props.buttonText ?? "Load more folders"
                })
                .finally(() => {
                    removeClass(loadMoreButton, 'is-loading')
                    setIsLoading(false)
                })
        } else {
            api.getDriveFolders(props.drive, props.drive.nextPageToken)
                .then(r => {
                    loadMoreSpan.innerHTML = props.buttonText ?? "Load more folders"
                })
                .finally(() => {
                    removeClass(loadMoreButton, 'is-loading')
                    setIsLoading(false)
                })
        }
    },[api,loadMoreButton,loadMoreSpan,props.buttonText,props.drive,props.folder])


    useEffect(() => { //infiinte scroll for lots of folders
        if (props.infiniteScroll  && props.visible && isLoading === false && entry.intersectionRatio > 0.45 && loadMoreButton) {
            showMore();
        }

    }, [showMore,entry.intersectionRatio, ref, isLoading, loadMoreSpan, props.visible, loadMoreButton, props.infiniteScroll]);




    if (props.visible) {
        return (

            <div ref={ref} className={`load-more__container ${className}`}>

                <button onClick={showMore} id={`loadMoreButton_${parentId}`}>
                    <FontAwesomeIcon
                        className="icon-folder"
                        icon={faFolderPlus}
                    />
                    <span className={"load-more--name"} id={`loadMoreSpan_${parentId}`}>
                        {props.buttonText ?? 'Load More Folders'}
                    </span>
                </button>
            </div>
        )
    }
    return (
        <></>
    )


}

export default LoadMoreFoldersButton
