import './styles.scss';
import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFolder, faFolderOpen} from '@fortawesome/free-solid-svg-icons';
import FolderList from "../FolderList";
import LoadMoreFoldersButton from "../LoadMoreFoldersButton";
import ExpandCollapseButton from "../ExpandCollapseButton";
import RefreshIcon from "../RefreshIcon";


function Folder(props) {

    const [isOpen, setIsOpen] = useState(false);
    const api = props.api;
    const drive = props.drive;
    const folder = props.folder;
    const nextPageToken = props.nextPageToken;
    const subfolders = props.subfolders ?? [];

    const toggleFolders = () => {
        setIsOpen(!isOpen);
    }

    const folderClick = async () => {
        setIsOpen(true)
        await api.selectFolder(drive, folder);
    }

    return (
        <div className="folder__container" title={folder.name}>
            <div className="folder--name">

                <RefreshIcon
                    onClick={() => api.refreshFolder(drive, folder)}
                    isOpen={isOpen}
                />

                <ExpandCollapseButton
                    folders={subfolders}
                    isOpen={isOpen}
                    onClick={toggleFolders}

                />

                <FontAwesomeIcon icon={(subfolders.length > 0 && isOpen) ? faFolderOpen : faFolder}
                                 className='icon-folder'/>

                <button data-folder-id={props.folder.id ?? null} onClick={folderClick}
                            className = {api.selectedFolder.id===props.folder.id ? 'active' : ''}>

                    {props.name}
                </button>
            </div>
            <div className="sub-folder__container">

                <FolderList folders={subfolders}
                            api={api}
                            drive={drive}
                            isOpen={isOpen}
                />
                <LoadMoreFoldersButton
                    className={"js-load-more-folders-button"}
                    api={api}
                    drive={drive}
                    visible={isOpen && nextPageToken}
                    folder={props.folder ?? false}
                    buttonText={`Load More Folders`}
                />

            </div>

        </div>
    );
}

export default Folder;