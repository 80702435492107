import './styles.scss';
import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogleDrive} from '@fortawesome/free-brands-svg-icons';
import FolderList from "../FolderList";
import LoadMoreFoldersButton from "../LoadMoreFoldersButton";
import RefreshIcon from "../RefreshIcon";
import ExpandCollapseButton from "../ExpandCollapseButton";


function Drive(props) {
    const [isOpen, setIsOpen] = useState(false);
    //const [drive,setDrive] = useState(props.drive);
    const drive = props.drive;
    const [folders,setFolders] = useState(props.drive.folders || [])
    const api = props.api;

    React.useEffect(() => {
        //setDrive(props.drive);
        setFolders(props.drive.folders || [])
        // console.log("New folders",props.drive.folders)

    }, [props.drive.folders])


    async function selectDrive(drive) {
        setIsOpen(true);
        await api.selectDrive(drive);
    }

    const toggleFolders = ()=>{
        setIsOpen(!isOpen);
    }

    let icon = <FontAwesomeIcon icon={faGoogleDrive} className="icon-folder"/>;




    return (
        <div className={"drive__container"}>

            <div className="drive--name">

                <RefreshIcon
                    onClick={() => {api.refreshDrive(drive)}}
                    isOpen={isOpen}
                />

                <ExpandCollapseButton
                    folders={folders || []}
                    isOpen={isOpen}
                    onClick={toggleFolders}

                />


                <button data-folder-id={drive.id}
                        onClick={async () => {
                            await selectDrive(drive);
                        }}>
                    {icon}
                    {drive.name}
                </button>


            </div>
            <div className={"folder__container"}>
                <FolderList folders={folders}
                            isOpen={isOpen}
                            drive={drive}
                            api={api}
                />
                <LoadMoreFoldersButton
                    className={"js-load-more-folders-button"}
                    drive={drive}
                    api={api}
                    visible={drive.nextPageToken && isOpen}
                    buttonText={`Load More Folders`}
                    infiniteScroll={true}
                />
            </div>
        </div>
    );
}

export default Drive;