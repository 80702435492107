import './styles.scss';
import React, {useState} from 'react';
import DefaultLogoutImage from './img/arrow-right-from-bracket-solid.png'

function LoginLogOutButton(props) {
    const [loggedIn,setLoggedIn] = useState(props.loggedIn || false);

    const buttonClick = ()=>{
       setLoggedIn(!loggedIn)
        props.onClick()
    }

    return (
        <button onClick={buttonClick}
                className={props.className || "login-button"}>
            <img alt="login logout icon"
                 height={props.height || "20"}
                 width={props.width || "20"}
                 src={props.src || DefaultLogoutImage}
                 align={props.align || "left"}/>
            <div>{props.message || (loggedIn ? "Logout" : "Login")}</div>
        </button>
    )
}

export default LoginLogOutButton